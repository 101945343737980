var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "pa-0",
    attrs: {
      "outlined": "",
      "elevation": "0",
      "rounded": "lg"
    }
  }, [_c('v-card-title', {
    staticClass: "justify-space-between"
  }, [_c('div', {
    staticClass: "d-flex"
  }, [_c('v-btn', {
    staticClass: "text-h5 font-weight-medium primary--text text-none",
    attrs: {
      "text": "",
      "to": "/skillpool/create"
    }
  }, [_c('v-icon', {
    attrs: {
      "left": "",
      "large": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.mdiPlus) + " ")]), _vm._v(" Create New Skill ")], 1)], 1)]), _c('v-card-text', {
    staticClass: "pa-0"
  }, [_c('skills', {
    attrs: {
      "skills": _vm.skills,
      "skillTags": _vm.skillTags,
      "hiringPlan": _vm.hiringPlan
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }