<template>
  <v-card outlined elevation="0" class="pa-0" rounded="lg">
    <v-card-title class="justify-space-between">
      <div class="d-flex">
        <v-btn
          class="text-h5 font-weight-medium primary--text text-none"
          text
          to="/skillpool/create"
        >
          <v-icon left large>
            {{ mdiPlus }}
          </v-icon>
          Create New Skill
        </v-btn>
      </div>
    </v-card-title>
    <v-card-text class="pa-0">
      <skills
        :skills="skills"
        :skillTags="skillTags"
        :hiringPlan="hiringPlan"
      ></skills>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiPlus } from '@mdi/js'
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex'
import Skills from './Skills.vue'

export default {
  props: {},
  data() {
    return {
      skills: [],
      skillTags: [],
      mdiPlus,
    }
  },
  computed: {
    ...mapState('customer', ['customer']),
    ...mapGetters('customer', ['hiringPlanKeys', 'prettyHiringPlans']),
    hiringPlan() {
      return this.prettyHiringPlans[
        this.hiringPlanKeys[this.customer?.getHiringPlan()]
      ]
    },
  },
  components: {},
  methods: {
    ...mapActions('recruiter', ['listSkills', 'fetchSkill']),
  },
  created() {
    console.log('created ......////')
    this.listSkills({
      userId: this.$store.getters['user/user'].uid,
    }).then((r) => {
      const x = r?.toObject()?.contentsList
      if (x.length > 0) {
        this.skills = x.filter((l) => l.contentType === 7)
        this.skillDlg = this.skills.map((_) => false)
      }
    })
  },
  components: {
    Skills,
  },
}
</script>
