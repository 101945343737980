var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "pa-0",
    attrs: {
      "outlined": "",
      "elevation": "0",
      "rounded": "lg"
    }
  }, [_c('v-card-title', {
    staticClass: "justify-space-between"
  }, [_c('div', {
    staticClass: "d-flex"
  }, [_c('v-btn', {
    staticClass: "text-h5 font-weight-medium primary--text text-none",
    attrs: {
      "text": "",
      "to": "/assessments/create"
    }
  }, [_c('v-icon', {
    attrs: {
      "left": "",
      "large": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.mdiPlus) + " ")]), _vm._v(" Create Assessment ")], 1)], 1), _c('div', [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "prepend-inner-icon": _vm.mdiMagnify,
      "dense": "",
      "label": "Search",
      "single-line": "",
      "hide-details": ""
    },
    model: {
      value: _vm.searchAssessment,
      callback: function ($$v) {
        _vm.searchAssessment = $$v;
      },
      expression: "searchAssessment"
    }
  })], 1)]), _c('v-card-text', {
    staticClass: "pa-0"
  }, [_c('v-data-table', {
    attrs: {
      "headers": _vm.assessmentHeaders,
      "items": _vm.assessments,
      "loading": _vm.customerTestsLoading,
      "search": _vm.searchAssessment,
      "sort-by": "date",
      "id": "assessments-table"
    },
    on: {
      "click:row": _vm.navigateToTest
    },
    scopedSlots: _vm._u([{
      key: "item.name",
      fn: function (_ref) {
        var item = _ref.item;
        return [item.tags.includes('purpose/sample') ? _c('div', {
          staticClass: "text-none text-body-1",
          attrs: {
            "text": "",
            "id": "sample-assessment"
          }
        }, [_vm._v(" " + _vm._s(item.name) + " ")]) : _c('div', {
          staticClass: "text-none text-body-1",
          attrs: {
            "text": ""
          }
        }, [_vm._v(" " + _vm._s(item.name) + " ")])];
      }
    }, {
      key: "item.date",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('div', [_vm._v(" " + _vm._s(_vm.formatDate(item.date)) + " ")])];
      }
    }, {
      key: "item.progress",
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_c('v-progress-linear', {
          attrs: {
            "rounded": "",
            "value": item.progress * 100 / item.candidates,
            "height": "15"
          }
        })];
      }
    }, {
      key: "item.archive",
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_c('v-btn', {
          attrs: {
            "icon": ""
          },
          on: {
            "click": function ($event) {
              $event.stopPropagation();
              return _vm.archiveTest(item);
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "color": "danger"
          }
        }, [_vm._v(" " + _vm._s(_vm.mdiPackageDown) + " ")])], 1)];
      }
    }, {
      key: "item.invaction",
      fn: function (_ref5) {
        var item = _ref5.item;
        return [_c('v-btn', {
          attrs: {
            "color": "primary",
            "text": ""
          },
          on: {
            "click": function ($event) {
              $event.stopPropagation();
              return _vm.inviteCandidate(item);
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "icon": "",
            "color": "primary"
          }
        }, [_vm._v(" " + _vm._s(_vm.mdiCloudUpload) + " ")])], 1)];
      }
    }, {
      key: "body.append",
      fn: function () {
        return [_vm.isEmptyState ? _c('tr', {
          staticClass: "empty_row"
        }, [_c('td'), _c('td'), _c('td', {
          staticClass: "d-flex flex-column py-5",
          staticStyle: {
            "height": "auto"
          }
        }, [_c('v-row', {
          attrs: {
            "align": "center",
            "justify": "center",
            "no-gutters": ""
          }
        }, [_c('v-img', {
          attrs: {
            "src": require("@/assets/images/campaign/createtest.png"),
            "max-width": "350",
            "max-height": "400"
          }
        })], 1), _c('v-btn', {
          staticClass: "text-h5 font-weight-medium primary--text text-none",
          attrs: {
            "text": "",
            "large": "",
            "to": "/assessments/create"
          }
        }, [_c('v-icon', {
          attrs: {
            "left": "",
            "x-large": ""
          }
        }, [_vm._v(" " + _vm._s(_vm.mdiPlus) + " ")]), _vm._v(" Create Assessment ")], 1)], 1), _c('td'), _c('td'), _c('td')]) : _vm._e()];
      },
      proxy: true
    }])
  }), _c('v-tour', {
    attrs: {
      "name": "newSignupTour",
      "steps": _vm.newSignupTour
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }