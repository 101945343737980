var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    style: {
      zIndex: _vm.options.zIndex
    },
    attrs: {
      "max-width": _vm.options.width
    },
    on: {
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])) return null;
        !_vm.rejection && _vm.cancel;
      }
    },
    model: {
      value: _vm.dialog,
      callback: function ($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('v-card', {
    staticClass: "pa-5"
  }, [_c('v-card-title', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !!_vm.message,
      expression: "!!message"
    }],
    staticClass: "pa-4 text-h5 justify-center text-center"
  }, [_c('div', [_vm._v(" To "), _c('span', {
    staticStyle: {
      "color": "red"
    }
  }, [_vm._v("UNLOCK")]), _vm._v(" this feature, become a "), _c('span', {
    staticStyle: {
      "color": "green"
    }
  }, [_vm._v("PREMIUM")]), _vm._v(" member today! ")])]), _c('v-card-text', [_c('v-row', {
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-col', {
    staticClass: "d-flex justify-center",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-img', {
    attrs: {
      "max-width": "600px",
      "height": "450px",
      "src": require(`../../assets/${_vm.imageUrl}`)
    }
  })], 1)], 1)], 1), _c('v-card-actions', {
    staticClass: "py-4 px-8 justify-center"
  }, [_vm.rejection ? _c('v-btn', {
    staticClass: "text-none text-subtitle-1 font-weight-medium px-2",
    attrs: {
      "large": ""
    },
    nativeOn: {
      "click": function ($event) {
        return _vm.cancel.apply(null, arguments);
      }
    }
  }, [_vm._v(" LATER ")]) : _vm._e(), _vm.rejection ? _c('v-spacer') : _vm._e(), _c('v-btn', {
    staticClass: "text-none text-subtitle-1 font-weight-medium px-2",
    attrs: {
      "large": "",
      "color": _vm.options.color
    },
    nativeOn: {
      "click": function ($event) {
        return _vm.agree.apply(null, arguments);
      }
    }
  }, [_c('router-link', {
    staticStyle: {
      "color": "white",
      "text-decoration": "none"
    },
    attrs: {
      "to": {
        name: 'Plans and Billing'
      },
      "target": "_blank"
    }
  }, [_vm._v(" UNLOCK NOW ")])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }